<template>
  <router-view :key="$route.params.user_id" />
</template>

<script>
export default {
  mounted() {
    this.$store.commit('adminPanel/setViewTitle', {
      depth: 0,
      title: 'Users',
      to: { name: 'users-listing' },
    });
  },
};
</script>

<style></style>
